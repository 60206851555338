<template>
  <div>
    <cui-register />
  </div>
</template>
<script>
import CuiRegister from '@/components/cleanui/system/Auth/Register'
export default {
  components: {
    CuiRegister,
  },
}
</script>
