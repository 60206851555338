<template>
  <div>
    <div class="card mt-5" :class="$style.container">
      <div>
        <img src="resources/images/medisync-withtext.svg" alt="Medisync" height="52" class="mb-4">
      </div>
      <div class="mb-4">
        <div class="h1" :class="$style.welcomeText">Selamat datang!</div>
        <div class="h6">Buat akun Sistem Rekam Medis Elektronik</div>
      </div>
      <button
          size="large"
          class="text-center w-100 mb-4 d-flex align-items-center justify-content-center"
          :class="$style.googleButton"
          @click="handleGoogleLogin"
        >
          <img src="resources/images/google-icon.svg" alt="Google" height="26" class="mr-2">
          <div class="font-size-18 justify-content-center">Daftar dengan Google</div>
        </button>

        <div ref="googleLoginBtn" hidden />

        <div class="d-flex align-items-center justify-content-center my-3">
          <div :class="$style.line"></div>
          <span class="mx-2">atau daftar dengan</span>
          <div :class="$style.line"></div>
        </div>

      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <div class="h6 font-weight-bold">Nama Lengkap<span class="text-danger">*</span></div>
          <a-input
            size="default"
            placeholder="Nama lengkap"
            v-decorator="[
              'name',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Masukkan nama lengkap Anda!' },
                ],
              },
            ]"
          />
        </a-form-item>
        
        <a-form-item>
          <div class="h6 font-weight-bold">Tipe Fasilitas Pelayanan Kesehatan<span class="text-danger">*</span></div>
          <a-select
            size="default"
            placeholder="Pilih tipe fayankes"
            @change="handleFayankesTypeClick"
            v-decorator="[
                'tipeFasyankes',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Pilih tipe fasilitas pelayanan kesehatan Anda!' },
                  ],
                },
              ]"
          >
            <a-select-option value="" disabled selected>Pilih tipe fayankes</a-select-option>
            <a-select-option v-for="fayankes in fayankesList" :key="fayankes" :value="fayankes"> {{ fayankes }} </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <div class="h6 font-weight-bold">Nama Fasilitas Pelayanan Kesehatan<span class="text-danger">*</span></div>
          <a-select
            show-search
            placeholder="Nama fasyankes"
            option-filter-prop="children"
            :filterOption="(input, option) => option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0"
            v-decorator="[
                'medical_facility_id',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan nama fasilitas pelayanan kesehatan Anda!' },
                  ],
                },
              ]"
          >
            <a-select-option value="" disabled selected>Nama fasyankes</a-select-option>
            <a-select-option v-for="item in medicalFacility" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <div class="h6 font-weight-bold">Role Akun<span class="text-danger">*</span></div>

          <a-select
            v-model="selectedRole"
            mode="multiple"
            size="default"
            placeholder="Pilih role"
            v-decorator="[
                'roles',
                {
                  // initialValue: null,
                  rules: [
                    { required: true, message: 'Pilih role Anda!' },
                  ],
                },
              ]"
          >
            <!-- <a-select-option value="" disabled selected>Pilih role</a-select-option> -->
            <a-select-option value="dokter">Dokter</a-select-option>
            <a-select-option value="perawat">Perawat</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-if="this.isDoctorRole()">
          <div class="h6 font-weight-bold">Spesialisasi (Layanan)<span class="text-danger">*</span></div>

          <a-select
            size="default"
            placeholder="Pilih Spesialisasi"
            v-decorator="[
                'specialization_id',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Pilih spesialisasi Anda!' },
                  ],
                },
              ]"
          >
            <a-select-option value="" disabled selected>Pilih spesialisasi dokter</a-select-option>
            <a-select-option v-for="specialization in doctorSpecializations" :key="specialization.id" :value="specialization.id">
              {{ specialization.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <div class="h6 font-weight-bold">Email<span class="text-danger">*</span></div>
          <a-input
            size="default"
            placeholder="Email"
            v-decorator="[
              'email',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Masukkan email Anda!' },
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ],
              },
            ]"
          />
        </a-form-item>

        <div class="h6 font-weight-bold">Nomor Telepon Selular<span class="text-danger">*</span></div>
        <div class="d-flex">
          <a-form-item class="col-xl-2">
            <a-select v-decorator="['phone_no_code', { initialValue: '+62', rules: [ { required: true, message: 'Kode negara harus dipilih' }],}]" placeholder="Kode Negara" style="width: 100%">
              <a-select-option v-for="code in phoneCode" :key="code.country" :value="code.code"> {{ code.code }} </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="col-xl-10">
            <a-input
              size="default"
              placeholder="0000000000"
              v-decorator="[
                'phone_no',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan nomor telepon Anda!' },
                    { pattern: /^\d{5,}$/, message: 'Nomor telepon tidak valid' },
                  ],
                },
              ]"
              autocomplete="tel"
            />
          </a-form-item>
        </div>

        <div class="d-flex flex-row justify-content-between">
          <a-form-item>
            <div class="h6 font-weight-bold">Kata Sandi<span class="text-danger">*</span></div>
            <a-input-password
              size="default"
              placeholder="Kata sandi"
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan kata sandi Anda!' },
                    { min: 8, message: 'Kata sandi minimal 8 karakter!'}
                  ],
                },
              ]"
              autocomplete="new-password"
              style="width: 100%"
            />
            <p class="small" style="height: 8px;">Kata sandi minimal 8 karakter</p>
          </a-form-item>
          <a-form-item>
            <div class="h6 font-weight-bold">Konfirmasi Kata Sandi<span class="text-danger">*</span></div>
            <a-input-password
              size="default"
              placeholder="Konfirmasi kata sandi"
              v-decorator="[
                'confirmPassword',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Konfirmasi kata sandi Anda!' },
                    { validator: this.checkPassword },
                  ],
                },
              ]"
              autocomplete="new-password"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        
        <a-button
          htmlType="submit"
          size="large"
          class="text-center w-100"
          type="primary"
          shape="round"
          :class="$style.submitButton"
          :loading="isLoading"
        >
          <strong class="h5">Daftar</strong>
        </a-button>

        <div class="text-left pt-2 mt-3">
          <span class="mr-2">Sudah punya akun?</span>
          <router-link to="/auth/login" class="kit__utils__link font-size-16">Masuk sekarang</router-link>
        </div>
      </a-form>
      <!-- <div>
        <span class="mr-1">By signing up, you agree to the</span>
        <a href="javascript: void(0);" class="kit__utils__link">Terms of Service</a> and
        <a href="javascript: void(0);" class="kit__utils__link">Privacy Policy</a>
      </div> -->
    </div>
  </div>
</template>
<script>
import { getAllSpecializations } from '@/services/axios/api/dokter';
import { getPhoneCode } from '@/services/axios/api/phoneCode';

export default {
  name: 'CuiRegister',
  computed: {
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      fayankesList: ["Tempat Praktik Mandiri", "Puskesmas", "Klinik", "Rumah Sakit", "Apotek", "Laboratorium Kesehatan", "Balai", "Lainnya"],
      selectedFayankes: null,
      roles: [],
      selectedRole: null,
      medicalFacilityType: null,
      medicalFacility: [
        { value: 1, label: "Fasilitas Kesehatan 1" },
        { value: 2, label: "Fasilitas Kesehatan 2" },
        { value: 3, label: "Fasilitas Kesehatan 3" },
      ],
      doctorSpecializations: [],
      isLoading: false,
      phoneCode: [],
    }
  },
  mounted() {
    const gClientId = ['737348619545-tlrb4m5pbu3ateh4vjai7p7v9c3mq409.apps.googleusercontent.com']
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
      auto_select: true,
    })

    this.getSpecializations()
    this.getPhoneCode()
  },
  methods: {
    handleSubmit(e) {
      this.isLoading = true
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (values.phone_no && values.phone_no.startsWith('0')) {
          values.phone_no = values.phone_no.replace(/^0+/, '')
        }
        values.phone_no = values.phone_no_code + values.phone_no
        delete values.phone_no_code
        // this.roles = []
        // this.roles.push(values.roles)
        // values.roles = this.roles
        if (!err) {
          const res = await this.$store.dispatch('user/REGISTER', { payload: values })
          console.log('Register Response:', res)
          if (res === false){
            this.isLoading = false
          }
          // console.log(values)
        }
      })
    },
    handleFayankesTypeClick(value) {
      this.medicalFacilityType = value;
      console.log('Selected Fayankes Type:', this.medicalFacilityType);
    },
    checkPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('password')) {
        callback(new Error('Password tidak cocok!'))
      } else {
        callback()
      }
    },

    isDoctorRole() {
      return this.selectedRole && this.selectedRole.includes('dokter')
    },

    handleGoogleLogin(){
      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {},
      )

      const googleLoginButton = this.$refs.googleLoginBtn.querySelector("div[role=button]");
      // console.log('Google Login Button:', googleLoginButton);
      if (googleLoginButton) {
        googleLoginButton.click();
        // console.log('Google Login Button Clicked');
      }
    },
    
    handleCredentialResponse(response) {
      // console.log(response.credential)
      // console.log('response', response)
      this.$store.dispatch('user/LOGIN_GOOGLE', {
        payload: { ...response },
      })
    },

    async getSpecializations(){
      try {
        const response = await getAllSpecializations()
        console.log(response.specializations)
        this.doctorSpecializations = response.specializations
      } catch (error) {
        console.log(error)
      }
    },

    async getPhoneCode(){
      try {
        const response = await getPhoneCode()
        console.log(response)
        this.phoneCode = response
      } catch (error) {
        console.log(error)
      }
    },
  },
}

</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
